<template>
  <div class="product-list-container">
    <div class="container">
      <h3>Products</h3>
      <div class="filters">
        <div
          v-for="(filterGroup, index) in availableFiltersGrouped"
          :key="index"
          class="filter-group"
        >
          <div
            v-for="(filter, filterIndex) in filterGroup"
            :key="filterIndex"
            class="filter"
          >
            <input
              type="checkbox"
              :id="filter.toLowerCase()"
              :value="filter"
              v-model="selectedFilters"
            />
            <label :for="filter.toLowerCase()">{{ filter }}</label>
          </div>
        </div>
      </div>

      <transition-group
        class="product-list"
        tag="div"
        name="list-complete"
        mode="out-in"
      >
        <router-link
          v-for="product in productsListFiltered"
          :key="product.id"
          :to="{ path: product.uri }"
          class="product-item"
          :data-tags="
            product.productTags.map(productTag => productTag.title).join(' ')
          "
          role="button"
        >
          <img
            v-if="product.image && product.image.length"
            :src="product.image[0].url"
            :alt="product.image[0].title"
          />
          <span v-else>Missing Image</span>
          <div class="product-data">
            <h4>{{ product.title }}</h4>
            <span>{{ juiceOrPuree(product) }}</span>
            <span>{{ concentrateOrNFC(product) }}</span>
          </div>
        </router-link>
      </transition-group>
      <transition name="fade" :duration="{ enter: 1000, leave: 0 }">
        <p v-if="!productsListFiltered.length" :key="`no-products-available`">
          No products available for the filters applied.
        </p>
      </transition>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import apiHelper from '@/api/helpers/helpers'

const VEGETABLE_FILTER = 'Vegetable'
const FRUIT_FILTER = 'Fruit'

export default {
  name: 'ProductListContainer',
  props: {
    sortedIds: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data() {
    return {
      availableFilters: [
        FRUIT_FILTER,
        VEGETABLE_FILTER,
        'Juice',
        'Puree',
        'Organic',
        'Conventional',
        'Concentrate',
        'NFC',
        'Frozen',
        'Aseptic'
      ],
      selectedFilters: [],
      productsList: []
    }
  },
  computed: {
    availableFiltersGrouped() {
      let result = []

      for (let i = 0; i < this.availableFilters.length; i += 2) {
        let chunk = this.availableFilters.slice(i, i + 2)
        result.push(chunk)
      }

      return result
    },
    productsListFiltered() {
      let productsList = this.productsList
      return productsList
        .sort(
          (a, b) => this.sortedIds.indexOf(a.id) - this.sortedIds.indexOf(b.id)
        )
        .filter(product => {
          const productTags = product.productTags.map(
            productTag => productTag.title
          )

          for (const selectedFilter of this.selectedFilters) {
            if (productTags.indexOf(selectedFilter) === -1) {
              return false
            }
          }

          return true
        })
    }
  },
  watch: {
    selectedFilters(newVal) {
      const newestFilter = newVal[newVal.length - 1]
      const vegetableIndex = newVal.indexOf(VEGETABLE_FILTER)
      const fruitIndex = newVal.indexOf(FRUIT_FILTER)

      if (newestFilter === FRUIT_FILTER && vegetableIndex !== -1) {
        newVal.splice(vegetableIndex, 1)
        return
      }

      if (newestFilter === VEGETABLE_FILTER && fruitIndex !== -1) {
        newVal.splice(fruitIndex, 1)
      }
    }
  },
  mounted() {
    this.loadProductsList()
  },
  methods: {
    loadProductsList() {
      const storedResponse = apiHelper.readStoredResponse('productList')

      this.productsList = storedResponse ? storedResponse.entries : []

      api.fruitsAndVegetables.loadProductsList().then(response => {
        this.productsList = response.entries || []
      })
    },
    juiceOrPuree(product) {
      const juiceOrPuree = product.productTags
        .filter(
          productTag => ['Juice', 'Puree'].indexOf(productTag.title) !== -1
        )
        .map(productTag => productTag.title)

      return juiceOrPuree.length === 2 ? 'Juice & Puree' : juiceOrPuree[0]
    },
    concentrateOrNFC(product) {
      const concentrateOrNFC = product.productTags
        .filter(
          productTag => ['Concentrate', 'NFC'].indexOf(productTag.title) !== -1
        )
        .map(productTag => productTag.title)

      return concentrateOrNFC.length === 2
        ? 'NFC | Concentrate'
        : concentrateOrNFC[0]
    }
  }
}
</script>

<style lang="scss" scoped>
.product-list-container {
  background-image: linear-gradient(
    rgba(44, 68, 55, 0.35),
    rgba(255, 255, 255, 0) 350px,
    rgba(255, 255, 255, 0)
  );

  .container {
    padding: 95px 0 !important;

    h3 {
      @apply uppercase;
      font-size: 62px;
    }

    .filters {
      @apply flex;
      @apply flex-wrap;
      @apply w-full;
      @apply justify-center;
      margin: 0 auto;
      padding: 48px 0;

      .filter-group {
        @apply inline-block;
        @apply w-1/3;

        .filter {
          @apply flex;
          @apply flex-col;
          @apply items-center;

          input {
            @apply absolute;
            @apply opacity-0;
          }

          label {
            @apply uppercase;
            @apply cursor-pointer;
            font-family: FuturaPtMedium, serif;
            font-size: 12px;
            letter-spacing: 2px;
            padding: 0;
            transition: all 0.3s linear;

            &:after {
              @apply block;
              @apply rounded-full;
              content: ' ';
              width: 26px;
              height: 26px;
              background-color: #d9dbdb;
              border: 3px solid #ffffff;
              box-shadow: 0 0 9px #d9dbdb;
              transition: all 0.2s linear;
              margin: 10px auto;
            }
          }

          input:checked + label:after {
            background-color: #525e6e;
          }

          input:hover + label:after,
          input:focus + label:after,
          label:hover:after {
            box-shadow: 0 0 9px rgba(82, 94, 110, 0.7);
          }

          input:hover,
          label:hover {
            transform: scale(1.1);
          }
        }
      }
    }

    .product-list {
      @apply flex;
      @apply flex-wrap;
      @apply items-center;
      @apply justify-center;

      .product-item {
        @apply relative;
        @apply inline-block;
        @apply w-3/4;
        @apply overflow-hidden;
        @apply cursor-pointer;
        @apply opacity-100;
        @apply scale-100;
        transition: all 0.3s ease-in;
        margin: 30px;
        box-shadow: 0 0 20px #d9dbdb;
        border-radius: 12px;

        &.list-complete-enter {
          @apply opacity-0;
          transform: scale(0);
        }
        &.list-complete-enter-to {
          @apply opacity-100;
          transform: scale(1);
        }
        &.list-complete-leave {
          @apply opacity-100;
          @apply w-1/5;
        }
        &.list-complete-leave-to {
          @apply opacity-0;
          @apply w-0;
        }
        &.list-complete-leave-active {
          @apply absolute;
        }

        &:focus,
        &:hover {
          .product-data {
            @apply opacity-100;

            h4 {
              @apply opacity-100;
              transition-delay: 0.3s;
            }

            span {
              @apply opacity-100;

              &:nth-of-type(1) {
                transition-delay: 0.6s;
              }

              &:nth-of-type(2) {
                transition-delay: 0.9s;
              }
            }
          }
        }

        img {
          @apply w-full;
        }

        .product-data {
          @apply flex;
          @apply flex-col;
          @apply justify-center;
          @apply absolute;
          @apply top-0;
          @apply left-0;
          @apply w-full;
          @apply h-full;
          @apply text-white;
          @apply opacity-0;
          background-color: rgba(82, 94, 110, 0.7);
          transition: opacity 0.3s ease-in;

          h4 {
            @apply relative;
            @apply uppercase;
            @apply opacity-0;
            font-size: 22px;
            line-height: 1.8em;
            background-color: #525e6e;
            transition: opacity 0.6s ease-in;
            margin-bottom: 14px;
          }

          span {
            @apply opacity-0;
            transition: opacity 0.6s ease-in;
            padding: 2px 0;
          }
        }
      }
    }

    p {
      font-size: 18px;
      padding-top: 30px;
      padding-bottom: 30px;

      &.fade-enter-active,
      &.fade-leave-active {
        transition: opacity 1s ease;
      }
      &.fade-enter,
      &.fade-leave-to {
        opacity: 0;
      }
    }
  }

  @media screen and (min-width: 375px) {
    .container {
      .product-list {
        .product-item {
          @apply w-1/3;
        }
      }
    }
  }

  @media screen and (min-width: 600px) {
    .container {
      .filters {
        .filter-group {
          @apply w-1/5;

          .filter {
            label {
              font-size: 16px;
              padding: 10px 0;
            }
          }
        }
      }

      .product-list {
        .product-item {
          @apply w-1/5;
        }
      }

      p {
        font-size: 24px;
        padding-top: 60px;
        padding-bottom: 60px;
      }
    }
  }

  @media screen and (min-width: 900px) {
    .container {
      .filters {
        @apply w-4/5;
      }
    }
  }
}
</style>
