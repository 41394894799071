<template>
  <div id="homePage">
    <HeroContainer @loaded="toggleShowPageTrue" :hero-data="heroData" />
    <ProductListContainer :sorted-ids="productListSortedIds" />
    <HighlightsTowerContainer :highlights-tower-data="highlightsTowerData" />
    <BackgroundImage
      v-if="backgroundImageData"
      :background-image="backgroundImageData"
    />
  </div>
</template>

<script>
import api from '@/api'
import HeroContainer from '@/components/Hero/HeroContainer'
import HighlightsTowerContainer from '@/components/HighlightsTower/HighlightsTowerContainer'
import ProductListContainer from '@/components/Product/ProductListContainer'
import viewMixin from '@/mixins/viewMixin'
import BackgroundImage from '@/components/ui/BackgroundImage'
import apiHelper from '@/api/helpers/helpers'

export default {
  name: 'FruitsAndVegetables',
  components: {
    BackgroundImage,
    HeroContainer,
    HighlightsTowerContainer,
    ProductListContainer
  },
  mixins: [viewMixin],
  data() {
    return {
      heroData: [],
      highlightsTowerData: [],
      backgroundImageData: null,
      productListSortedIds: []
    }
  },
  mounted() {
    this.loadFruitsAndVegetablesPage()
  },
  methods: {
    loadFruitsAndVegetablesPage() {
      this.setFruitsAndVegetablesData(
        apiHelper.readStoredResponse('fruitsAndVegetables')
      )

      api.fruitsAndVegetables.loadFruitsAndVegetablesPage().then(response => {
        this.setFruitsAndVegetablesData(response)
      })
    },
    setFruitsAndVegetablesData(response) {
      if (!response || !response.entry) return

      this.heroData = response.entry.hero || []
      this.productListSortedIds = response.entry.fruitsVegetablesList.map(
        product => product.id
      )
      this.highlightsTowerData = response.entry.highlightsTower || []
      this.backgroundImageData = response.entry.backgroundImage[0] || null
    }
  }
}
</script>
